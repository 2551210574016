<template>
  <div>
    <Modal v-model="showModal" :width="1200" :mask-closable="false" @on-visible-change="modalShowChange" footer-hide>
      <p slot="header" class="workplatform-title" >
          <span>配置订单金额</span>
      </p>
      <div class="workplatform-title">订单基础信息</div>
      <Row>
        <i-col span="12"><span  class="title">发布品牌 </span>{{orderBasic.brandName}}</i-col>
        <i-col span="12"><span  class="title">订单备注 </span>{{orderBasic.remark}}</i-col>
      </Row>
      <Row class="p-b-5">
        <i-col span="12"><span  class="title">发布日期 </span><Tag type="border" color="black">{{orderBasic.startDate}} 至 {{orderBasic.endDate}} </Tag></i-col>
        <i-col span="12"><span class="title">创建时间 </span>{{orderBasic.createTime}}</i-col>
      </Row>
      <div class="workplatform-title">增值服务</div>
      <Row class="p-b-5">
        <i-col span="8">
            <span class="title">定位服务 </span>
            <Icon v-if="orderBasic.fixProtect" color="lightgreen" size="20" type="md-checkbox-outline" />
            <span v-else>无</span>
        </i-col>
        <i-col span="8">
            <span class="title">不确认方案 </span>
            <Icon v-if="!orderBasic.fixed" color="lightgreen" size="20" type="md-checkbox-outline" />
            <span v-else>无</span>
        </i-col>
        <i-col span="8">
            <span  class="title">行业保护 </span>
            <template v-if="orderBasic.industryProtect">
                <Icon color="green" size="20" type="md-checkbox-outline" />
                {{orderBasic.industryProtectName}}
            </template>
            <span v-else>无</span>
        </i-col>
      </Row>

      <div class="hrHead workplatform-title">设置媒体金额</div>
      <Row class="text-bold">
          <i-col span="3"  offset="3">产品名称</i-col>
          <i-col span="3">采购数量</i-col>
          <i-col span="3">媒体费刊例</i-col>
          <i-col span="3">发布费（折扣）</i-col>
          <!-- <i-col span="3">发布费折扣</i-col> -->
          <i-col span="3">服务费刊例</i-col>
          <i-col span="3">服务费</i-col>
          <i-col span="3">操作</i-col>
          <!-- <i-col span="2">上下刊费</i-col> -->
          <!-- <i-col span="3">定位保护费</i-col> -->
      </Row>
      <schedulePriceSetting v-for="item in category" :key="item.categoryId"
        :disablePrice="disablePrice" :schedules="item.schedules" :hasLimit="hasLimit" :fixProtect="orderBasic.fixProtect"
        @on-settled="handlePriceSettled" @on-serviceSetting="handleServiceSetting"></schedulePriceSetting>

      <p v-if="disablePrice" class="text-green text-center text-16" >
          当前订单价格已在确认单设置，并审批通过，所以您无法重新设置
      </p>

      <!-- <div slot="footer">
        <span class="text-red" v-show="!showConfirm">请配置完所有媒体金额</span>
        <i-button class="btn-black" @click="handleCancel">关闭</i-button>
        <i-button type="primary" :disabled="!showConfirm" @click="handleConfirm">确认</i-button>
      </div> -->

      <Spin fix size="large" v-if="spinShow">
          <Icon type="ios-loading" size=18 class="spin-icon-load"></Icon>
          <div>加载中...</div>
      </Spin>
    </Modal>
    <ServiceSetting @on-finished="handleSettingFinished" :isShow.sync="showServiceSettingModal" :orderId="orderId" :product="product"  :contractId="contractId" :versionId="versionId"></ServiceSetting>
  </div>

</template>

<script>
import schedulePriceSetting from './SchedulePriceSettingNew'
import ServiceSetting from './ServiceSetting'

import { getOrder } from '@/api/order/order'
import { getOrderPrice } from '@/api/scp/contractorder'

// import { getConfirmation } from '@/api/order/confirmation'
import { buildCategories } from '@/utils/orderUtils'

export default {
  props: {
    orderId: {
      type: Number
    },
    isShow: {
      type: Boolean,
      required: true
    },
    contractId: {
      type: [Number, String],
      required: true
    },
    contractType: {
      type: [Number, String],
      required: true
    },
    versionId: {
      type: [Number, String],
      required: true
    },
    hasLimit: {
      type: Boolean
    }

  },
  data () {
    return {
      orderContractSchedulePriceBeanList: [],
      orderBasic: { },
      showModal: false,
      category: [],
      productPriceMap: new Map(),
      products: [],
      showConfirm: false,
      spinShow: false,
      disablePrice: false,

      showServiceSettingModal: false, // 显示服务费窗口
      product: {}
    }
  },
  components: {
    schedulePriceSetting, ServiceSetting
  },
  methods: {
    getDetail () {
      // 获取当前订单的基本信息
      getOrder({ orderId: this.orderId }).then(res => {
        this.orderBasic = res
      })

      // const query = { orderId: this.orderId }
      // getConfirmation(query).then(res => {
      //   if (res.id === -1 || res.status === 0) { // 未所属确认单，价格从SCP获取
      // this.disablePrice = false
      this.initOrderPrice()
    },
    initOrderPrice () {
      // 获取当前订单的执行价，历史保存值SCP
      getOrderPrice({ orderId: this.orderId, contractId: this.contractId, version: this.versionId }).then(res => {
        // 获取当前订单内产品的刊例价和最低结算价
        this.products = res
        this.category = buildCategories(res)
        this.spinShow = false
        // getorderplanprice({ orderId: this.orderId }).then(products => {
        //   this.products = products
        //   //   this.products = products.sort(function (a, b) {
        //   //   return a.productId > b.productId ? 1 : -1
        //   // })

        //   const productWithPrice = products.map(p => {
        //     let priceSetting = {}
        //     const historyPriceSeting = res.find(x => x.goodId === p.goodId && x.startDate === p.startDate && x.endDate === p.endDate)
        //     if (historyPriceSeting) { // 合同变更或者是编辑
        //       // historyPriceSeting.normalSellPrice += historyPriceSeting.recommendSellPrice
        //       // historyPriceSeting.recommendSellPrice = 0
        //       // 如果是代理商合同，每次从订单获取最新值
        //       // if (this.contractType === 3) {
        //       //   historyPriceSeting.producePrice = p.totalProducePrice
        //       //   historyPriceSeting.installPrice = p.totalInstallPrice
        //       // }

        //       priceSetting = historyPriceSeting
        //     } else {
        //       priceSetting = p
        //       //  { // 未设置过费用
        //       //   signNormalPrice: this.hasLimit ? p.signNormalPrice : 0, // 开启合同限价后，设置为默认最低折扣价。 否则为0
        //       //   producePrice: p.totalProducePrice, // 获取订单设置的上刊制作费
        //       //   installPrice: p.totalInstallPrice,
        //       //   monitorPrice: p.monitorPrice,
        //       //   breakScheduleSignPrice: p.breakScheduleSignPrice,
        //       //   fixProtectSignPrice: p.fixProtectSignPrice ? p.fixProtectSignPrice : 0,
        //       //   industryProtectSignPrice: p.industryProtectSignPrice
        //       // }
        //     }

        //     return Object.assign({}, p, priceSetting)
        //   })
        // })
      })
    },
    modalShowChange (val) {
      if (!val) {
        this.$emit('on-updated')
      }
    },

    handleSettingFinished () {
      this.initOrderPrice()
    },

    handlePriceSettled (data) {
      if (data.settled) {
        data.products.forEach(product => {
          this.productPriceMap.set(product.goodId + '~' + product.startDate + '~' + product.endDate, product)
        })
      }
      this.showConfirm = data.settled && this.productPriceMap.size === this.products.length
    },
    handleServiceSetting (dataV) {
      this.product = dataV.product
      this.showServiceSettingModal = true
    }
  },
  watch: {
    showModal (val) {
      this.$emit('update:isShow', val)
    },
    isShow (val) {
      this.showModal = val
      if (val) {
        this.productPriceMap.clear()
        this.spinShow = true
        this.getDetail()
      }
    }
  }
}
</script>
