<template>
  <div>
    <div style="margin-top: 15px" v-for="(sc,i) in schedules" :key="i">
      <div v-for="product in sc" :key="product.goodId">
        <Row>
            <i-col span="3">
              {{ getSchedule(sc) }}
            </i-col>
            <i-col span="3">{{product.goodName}}</i-col>
            <i-col span="3">
              {{product.signNormalQuantity}} {{product.productUnit}}
            </i-col>
            <i-col span="3">
              {{formatMoney(product.normalUsePrice)}} ({{ product.usePriceDiscount }})
            </i-col>
            <i-col span="3">
              {{formatMoney(product.signNormalPrice)}}{{computedSale(product.normalUsePrice ,product.signNormalPrice)}}
            </i-col>
            <!-- <i-col span="3">
              <InputNumber v-model="product.signNormalPrice" :disabled="disablePrice"  @on-blur="invalidSellNumber(product)" :min="0" size="small"></InputNumber>
              <Icon type="md-refresh" size="14" color="green" class="m-l-2" style="cursor: pointer;" @click="refreshPrice(product)"/>

            </i-col> -->
            <!-- <i-col span="3">
              <InputNumber :disabled="disablePrice" @on-change="changeSellSales($event, product)" :value="computedSale(product.normalUsePrice ,product.signNormalPrice)"
                :min="0" size="small"></InputNumber> 折
            </i-col> -->
            <i-col span="3">
              {{formatMoney(product.totalServicePrice)}}
            </i-col>
            <i-col span="3"> <!--  制作费 -->
              {{formatMoney(product.signServicePrice)}}
              <!-- <Icon type="ios-create-outline" size="14" color="#2d8cf0" style="cursor: pointer;" @click="EditServicePrice(product)"/> -->
                </i-col>
                <i-col span="3"> <!--  制作费 -->
                  <a @click="EditServicePrice(product)">调整</a>
                </i-col>
        </Row>

        <Divider dashed style="margin: 5px 0;" v-show="hasLimit && product.signNormalPrice<product.normalSettlementPrice">
          <small class="text-red">产品执行价不能小于 {{formatMoney(product.normalSettlementPrice)}}</small>
        </Divider>
      </div>
    </div>
  </div>
</template>

<script>
import { toMoney } from '@/utils/wnumb_own'
import { GetCurrentSchedule } from '@/utils/dateFormat'

export default {
  props: {
    schedules: {
      type: Array,
      required: true
    },
    hasLimit: {
      type: Boolean,
      default: false
    },
    fixProtect: Boolean,
    disablePrice: Boolean
  },
  data () {
    return {
      orderOptionalService: this.$store.getters.token.publisherSetting.orderOptionalService, // 是否显示增值服务
      disableChangeInstallPrice: false
    }
  },
  methods: {
    formatMoney (number) {
      return toMoney(number)
    },
    getSchedule (products) {
      if (products.length > 0) {
        const product = products[0]
        return GetCurrentSchedule(product.startDate, product.endDate)
      }
    },
    refreshPrice (product) {
      product.signNormalPrice = product.normalSettlementPrice
    },
    changeSellSales (val, produce) {
      produce.signNormalPrice = Number((produce.normalUsePrice * val / 10).toFixed(2))
    },
    computedSale (usePrice, sellPrice) {
      if (usePrice > 0) {
        const discount = (sellPrice / usePrice) * 10
        const showDisCount = discount.toFixed(2)
        return '(' + showDisCount + '折)'
      } else {
        return ''
      }
    },
    // 服务费采购
    EditServicePrice (product) {
      this.$emit('on-serviceSetting', { product: product })
    }
  },
  watch: {
    schedules: { // 监控档期费用录入后， 产生的变化。 直接提交到父窗体
      deep: true,
      handler: function () {
        let products = []
        this.schedules.forEach(x => {
          products = products.concat(x.map(product => {
            product.normalSignQuantity = product.normalQuantity
            product.fixProtectSignPrice = this.fixProtect
              ? (product.signNormalPrice) * product.fixProtectRate : product.fixProtectSignPrice// 定位保护费
            product.industryProtectSignPrice = (product.signNormalPrice) * product.industryProtectRate
            product.breakScheduleSignPrice = (product.signNormalPrice) * product.breakScheduleRate
            product.monitorPrice = product.totalMonitorPrice // 监播是固定的费率
            return product
          }))
        })

        if (this.hasLimit) {
          const settled = this.schedules.every(sc => sc.every(x => x.signNormalPrice >= x.normalSettlementPrice))
          this.$emit('on-settled', { products: products, settled: settled })
        } else {
          this.$emit('on-settled', { products: products, settled: true })
        }
      }
    }
  },
  created () {
    // 目前为全部允许输入
    // this.disableChangeInstallPrice = this.disablePrice || this.$store.getters.token.publisherSetting.contractType !== 1

    const settled = this.schedules.every(sc => sc.every(x => x.signNormalPrice >= x.normalSettlementPrice))
    let products = []
    this.schedules.forEach(sc => {
      products = products.concat(sc.map(product => {
        product.normalSignQuantity = product.normalQuantity
        return product
      }))
    })

    if (this.hasLimit) {
      this.$emit('on-settled', { products: products, settled: settled })
    } else {
      this.$emit('on-settled', { products: products, settled: true })
    }
  }

}
</script>

<style>
.serviceExample{
  padding: 5px;
  border: 1px solid #5cadff;
  border-radius: 3px;
  color: #808695;
  font-weight: 400;
  position: absolute;
  background-color: papayawhip;
  z-index: 999;
  width: 130px;
}
</style>
