<template>
  <div>
    <Row class="p-t-10 m-b-10 workplatform-title">
      <span>费用汇总</span>
    </Row>

    <i-table
      stripe
      :data="products"
      :columns="productColumns"
      :summary-method="handleSummary"
      show-summary
    ></i-table>

    <Modal v-model="showServiceDetailModal" :width="400" :mask-closable="false" footer-hide>
      <p slot="header" class="workplatform-title">
        <span>服务费明细</span>
      </p>
      <i-table stripe :data="serviceDetail" :columns="serviceColumns"></i-table>
    </Modal>
  </div>
</template>

<script>
import { parseUpperMoney } from '@/utils'

import { GetCurrentSchedule } from '@/utils/dateFormat'
import { toMoney } from '@/utils/wnumb_own'
export default {
  props: {
    orders: Array
  },
  data () {
    return {
      productColumns: [
        { title: '资源名称', key: 'productName' },
        {
          title: '投放档期',
          render: (h, data) => {
            return h(
              'span',
              GetCurrentSchedule(data.row.startDate, data.row.endDate)
            )
          }
        },
        {
          title: '购买数量',
          align: 'center',
          render: (h, data) => {
            return h('span', data.row.signNormalQuantity)
          }
        },
        // {
        //   title: '媒体刊例总价',
        //   key: 'amount',
        //   render: (h, data) => {
        //     return h('span', {
        //       attrs: {
        //         class: data.row.productName === '合计' ? 'money' : ''
        //       }
        //     }, this.formatMoney(data.row.amount))
        //   }
        // },

        {
          title: '媒体费刊例',
          key: 'normalUsePrice',
          render: (h, data) => {
            return h('div', [
              h(
                'span',
                {
                  attrs: {
                    class: data.row.productName === '合计' ? 'money' : ''
                  }
                },
                this.formatMoney(data.row.normalUsePrice)
              ),
              h('span', '(' + data.row.usePriceDiscount + ')')
            ])
          }
        },

        {
          title: '媒体发布费',
          key: 'signNormalPrice',
          render: (h, data) => {
            return h('div', [
              h(
                'span',
                { class: 'money' },
                this.formatMoney(data.row.signNormalPrice)
              ),
              h(
                'span',
                { class: 'discount' },
                this.computeShowDiscount(
                  data.row.normalUsePrice,
                  data.row.signNormalPrice
                )
              )
            ])
          }
        },
        // {
        //   title: '服务费',
        //   align: 'center',
        //   children: [
        //     {
        //       title: '名称',
        //       key: 'age',
        //       align: 'center'
        //     },
        //     {
        //       title: '数量',
        //       key: 'age',
        //       align: 'center'
        //     },
        //     {
        //       title: '价格',
        //       key: 'age',
        //       align: 'center'
        //     }
        //   ]
        // },
        {
          title: '服务费刊例',
          key: 'totalServicePrice',
          render: (h, data) => {
            return h('div', [
              h(
                'span',
                { class: 'money' },
                this.formatMoney(data.row.totalServicePrice)
              )
            ])
          }
        },
        {
          title: '服务费签约价',
          key: 'signServicePrice',
          render: (h, data) => {
            return h('div', [
              h(
                'span',
                { class: 'money' },
                this.formatMoney(data.row.signServicePrice)
              ),
              h(
                'span',
                { class: 'discount' },
                this.computeShowDiscount(
                  data.row.totalServicePrice,
                  data.row.signServicePrice
                )
              )
            ])
          }
        },
        // {
        //   title: '增值服务费',
        //   key: 'valueAddedServices',
        //   render: (h, data) => {
        //     return h('span', {
        //       attrs: {
        //         class: data.row.productName === '合计' ? 'money' : ''
        //       }
        //     }, this.formatMoney(data.row.valueAddedServices))
        //   }
        // },
        {
          title: '操作',
          key: 'operate',
          render: (h, data) => {
            return h(
              'a',
              {
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    this.showServiceDetail(data.row.goodServiceFeeVOList)
                  }
                }
              },
              '服务费明细'
            )
          }
        }
      ],
      serviceDetail: [],
      serviceColumns: [
        { title: '服务费名称', key: 'serviceItemName' },
        { title: '服务费类型', key: 'typeName' },
        {
          title: '资源数量',
          key: 'serviceQuantity',
          render: (h, data) => {
            return h('div', [
              h('span', {}, data.row.serviceQuantity + data.row.serviceQuantityName)
            ])
          }
        },
        {
          title: '刊例单价',
          key: 'price',
          render: (h, data) => {
            return h('div', [
              h('span', {}, data.row.price + '/' + data.row.serviceQuantityName)
            ])
          }
        },
        {
          title: '签约数量',
          key: 'signServiceQuantity',
          render: (h, data) => {
            return h('div', [
              h('span', {}, data.row.signServiceQuantity + data.row.serviceQuantityName)
            ])
          }
        },
        {
          title: '签约价',
          key: 'signServiceAmount',
          render: (h, data) => {
            return h('div', [
              h(
                'span',
                { class: 'money' },
                this.formatMoney(data.row.signServiceAmount)
              )
            ])
          }
        }
      ],
      showServiceDetailModal: false
    }
  },
  methods: {
    handleSummary ({ columns, data }) {
      const needSummarykey = [
        '',
        'signNormalPrice',
        'total',
        '',
        'signServicePrice'
      ]
      const sums = {}
      columns.forEach((column, index) => {
        const key = column.key
        if (index === 0) {
          sums[key] = {
            key,
            value: '合计'
          }
          return
        }
        if (needSummarykey.includes(key)) {
          const values = data.map((item) => Number(item[key]))
          if (!values.every((value) => isNaN(value))) {
            const v = values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev + curr
              } else {
                return prev
              }
            }, 0)
            sums[key] = {
              key,
              value: toMoney(v)
            }
          } else {
            sums[key] = {
              key,
              value: '-'
            }
          }
        } else {
          sums[key] = {
            key,
            value: '-'
          }
        }
      })

      return sums
    },
    formatMoney (number) {
      return toMoney(number)
    },
    formatUpperMoney (number) {
      return parseUpperMoney(number)
    },
    computeShowDiscount (denominator, member) {
      if (denominator > 0) {
        const discount = (member / denominator) * 10

        // toFixed()方法, 四舍五入
        const showDisCount = discount.toFixed(2)
        // Math.floor()，不四舍五入 ，向下取整
        // const showDisCount = Math.floor(discount * 100) / 100

        return '(' + showDisCount + '折)'
      } else {
        return ''
      }
    },
    showServiceDetail (detail) {
      this.serviceDetail = detail
      this.showServiceDetailModal = true
    }
  },
  computed: {
    products: function () {
      const items = new Map()
      this.orders.forEach((order) => {
        order.products.forEach((product) => {
          const productPrice =
            items.get(
              product.productId +
                '_' +
                product.startDate +
                '_' +
                product.endDate
            ) || {}

          let quantity = productPrice.signNormalQuantity || 0
          let realAmount = productPrice.signNormalPrice || 0
          let amount = productPrice.normalUsePrice || 0

          let useServiceprice = productPrice.totalServicePrice || 0
          let signServiceprice = productPrice.signServicePrice || 0

          // 合计总价， 汇总添加当前产品的费用

          realAmount += product.signNormalPrice || 0

          amount += product.normalUsePrice || 0
          // amount += product.usePrice || 0

          // amountTotal += product.usePrice || 0
          quantity += product.signNormalQuantity

          useServiceprice += product.totalServicePrice || 0
          signServiceprice += product.signServicePrice || 0

          const productSummary = {
            productName: product.productName,
            signNormalQuantity: quantity,
            startDate: product.startDate,
            endDate: product.endDate,
            signNormalPrice: realAmount,
            usePriceDiscount: product.usePriceDiscount,
            normalUsePrice: amount,
            totalServicePrice: useServiceprice,
            signServicePrice: signServiceprice,
            goodServiceFeeVOList: product.goodServiceFeeVOList
            // unit: product.productUnit,
            // priceunit: product.productUnit
          }
          items.set(
            product.productId + '_' + product.startDate + '_' + product.endDate,
            productSummary
          )
        })
      })
      const summary = [...items.values()]

      return summary
    }
  }
}
</script>
