<template>
  <div class="p-t-10">
    <fileUpload
    ref="fileuploadComponent"
     type="CONTRACT_ATTACHMENT_FILE"
      :existsFile="existsFile"
     :actionUrl="'/ooh-scp/v1/contractfile/uploadfile'"
     :downloadUrl="'/ooh-scp/v1/contractfile/downloadfile'"
    :relateId="(stepType === 1) ? params.contractId : params.newVersionId" :format="fileTypes"
     @on-uploaded="handleFilesUploaded"
     @on-removeFile="handleRemoveFile"
     ></fileUpload>
    <row class="p-b-10">
      <i-col span="24">
        <Button type="info" @click="handlePreviousStep">上一步</Button>
        <span class="text-right text-red" style="float: right;" v-show="disabled">您必须上传些附件才能继续</span>
        <Button type="info" v-show="!disabled" class="right" :loading="loadingNext" @click="handleNextStep">下一步</Button>
      </i-col>
    </row>
  </div>
</template>

<script>
import { deleteFiles, getFileList } from '@/api/scp/contractfile'
import fileUpload from '@/components/common/FileUploadV2'

export default {
  props: {
    params: {
      type: Object,
      required: true
    },
    stepType: {
      type: Number,
      default: 1
    }
  },
  components: {
    fileUpload
  },
  data () {
    return {
      fileTypes: ['img', 'jpg', 'png', 'doc', 'docx', 'pdf'],
      disabled: false,
      loadingNext: false,
      existsFile: []
    }
  },
  created () {
    this.getExistsFiles()
  },
  methods: {
    handlePreviousStep () {
      this.$emit('prev-step')
    },
    handleNextStep () {
      this.loadingNext = true
      this.$emit('next-step')
    },
    handleFilesUploaded (files) {
      if (this.params.publisherId === (3 || 5) && files.length < 1) { // 杭州和无锡必须上传附件
        this.disabled = true
      } else {
        this.disabled = false
      }
    },
    cannelLoading () {
      this.loadingNext = false
    },
    getExistsFiles () {
      const that = this
      const data = { type: 'CONTRACT_ATTACHMENT_FILE', relateId: (that.stepType === 1) ? that.params.contractId : that.params.newVersionId }
      getFileList(data).then(res => {
        that.existsFile = res.map(file => {
          file.name = file.fileName
          return file
        })
      })
    },
    handleRemoveFile (file) {
      const id = JSON.stringify([file.id || file.response.id])
      deleteFiles({ fileIds: id }).then(res => {
        this.$refs.fileuploadComponent.removeSuccess(file)
      })
    }

  }
}
</script>
