<template>
  <Modal v-model="showsettingModal" :width="600" :mask-closable="false">
    <p slot="header" class="workplatform-title">
      <span>设置媒体金额</span>
    </p>

    <div class="hrHead workplatform-title">设置发布费</div>
    <Row class="text-bold">
      <i-col span="6">发布费</i-col>
      <i-col span="6">发布费折扣</i-col>
      <i-col span="6"></i-col>
      <i-col span="6"></i-col>
    </Row>
    <Row>
      <i-col span="6">
        <InputNumber
          v-model="product.signNormalPrice"
          @on-blur="invalidSellNumber(product)"
          :min="0"
          size="small"
        ></InputNumber>
        <Icon
          type="md-refresh"
          size="14"
          color="green"
          class="m-l-2"
          style="cursor: pointer"
          @click="refreshPrice(product)"
        />
      </i-col>
      <i-col span="6">
        <InputNumber
          @on-change="changeSellSales($event, product)"
          :value="computedSale(product.normalUsePrice, product.signNormalPrice)"
          :min="0"
          size="small"
        ></InputNumber>
        折
      </i-col>
    </Row>
    <div class="hrHead workplatform-title" style="margin-top:50px;">设置服务项费用</div>
    <Row class="text-bold">
      <i-col span="4">服务项名称</i-col>
      <i-col span="4">服务项类型</i-col>
      <i-col span="4">数量</i-col>
      <i-col span="4">刊例价</i-col>
      <i-col span="4">采购量</i-col>
      <i-col span="4">签约额</i-col>
    </Row>
    <div style="margin-top: 15px" v-for="(sc, i) in services" :key="i">
      <Row>
        <i-col span="4">
          {{ sc.serviceItemName }}
        </i-col>
        <i-col span="4">
          {{ sc.typeName }}
        </i-col>
        <i-col span="4"> {{ sc.serviceQuantity }} {{ sc.serviceQuantityName }} </i-col>
        <i-col span="4"> {{ formatMoney(sc.price) }} /{{ sc.serviceQuantityName }} </i-col>
        <i-col span="4">
          <!--  总数量 -->
          <InputNumber
            v-model="sc.signServiceQuantity"
            :min="0"
            size="small"
          ></InputNumber>
          <!-- <template v-if="product.normalSellPrice>0">
                <br/>
                <span class="remark">折扣：</span> <span class="money">{{computedSale(product.normalUsePrice ,product.normalSellPrice)}} 折</span>
              </template> -->
        </i-col>
        <i-col span="4">
          <!--  签约价 -->
          <InputNumber
            v-model="sc.signServiceAmount"
            :min="0"
            size="small"
          ></InputNumber>
        </i-col>
      </Row>
    </div>
    <div slot="footer">
      <i-button class="btn-black" @click="handleCancelSetting">关闭</i-button>
      <i-button type="primary" @click="handleConfirmSetting">确认</i-button>
    </div>

    <Spin fix size="large" v-if="spinShow">
      <Icon type="ios-loading" size="18" class="spin-icon-load"></Icon>
      <div>加载中...</div>
    </Spin>
  </Modal>
</template>

<script>
import { getOrderGoodServiceFee, saveOrderGoodUseFee, saveGoodServiceFee } from '@/api/scp/contractorder'

import { toMoney } from '@/utils/wnumb_own'

export default {
  props: {
    product: {
      type: Object
    },
    orderId: {
      type: Number
    },
    contractId: {
      type: [Number, String],
      required: true
    },
    versionId: {
      type: [Number, String],
      required: true
    },
    isShow: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      services: [],

      showsettingModal: false,
      category: [],
      productPriceMap: new Map(),
      products: [],
      spinShow: false
    }
  },
  components: {},
  methods: {
    formatMoney (number) {
      return toMoney(number)
    },

    computedSale (usePrice, sellPrice) {
      return Number((sellPrice * 10 / usePrice).toFixed(2))
    },

    refreshPrice (product) {
      product.signNormalPrice = product.normalSettlementPrice
    },
    invalidSellNumber (produce) {
      if (!produce.signNormalPrice || produce.signNormalPrice === '') {
        produce.signNormalPrice = 0
      }
    },

    changeSellSales (val, produce) {
      produce.signNormalPrice = Number((produce.normalUsePrice * val / 10).toFixed(2))
    },
    getDetail () {
      // 获取当前订单的基本信息
      getOrderGoodServiceFee({
        orderId: this.orderId,
        goodUseFeeId: this.product.goodUseFeeId
      }).then((res) => {
        this.services = res

        this.spinShow = false
      })
    },
    handleConfirmSetting () {
      // const products = [...this.productPriceMap.values()]

      saveOrderGoodUseFee({
        orderId: this.orderId,
        versionId: this.versionId,

        productFees: JSON.stringify([{ goodUseFeeId: this.product.goodUseFeeId, signUseFee: this.product.signNormalPrice }])
      }).then((res) => {
        saveGoodServiceFee({
          orderId: this.orderId,
          versionId: this.versionId,

          serviceFees: JSON.stringify(this.services.map(item => {
            return {
              goodServiceFeeId: item.goodServiceFeeId, signServiceNum: item.signServiceQuantity, signServiceAmount: item.signServiceAmount
            }
          }))
        }).then((res) => {
          this.showsettingModal = false
          this.$emit('on-finished')
        })
      })
    },
    handleCancelSetting () {
      this.showsettingModal = false
    }
  },
  watch: {
    showsettingModal (val) {
      this.$emit('update:isShow', val)
    },
    isShow (val) {
      this.showsettingModal = val
      if (val) {
        this.productPriceMap.clear()
        this.spinShow = true
        this.getDetail()
      }
    }
  }
}
</script>
