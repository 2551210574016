<template>
  <div style="minHeight:200px">
    <i-table stripe :columns="titles" :data="customers" />
    <Row>
        <i-col span="8" class="p-t-10">
          <a @click="handleCreate">没找到？创建一个新的</a>
        </i-col>
        <i-col span="16">
          <div class="paging_style">
            <Page size="small" :total="total" :page-size="query.pageSize" show-total show-elevator @on-change="handlePageChange" ></Page>
          </div>
        </i-col>
    </Row>

     <Modal v-model="modalNewFirstParty" width="800" title="创建合同甲方">
        <i-form class="p-t-10" ref="firstPartyForm" :model="firstParty"  label-position="top" :rules="rules">
          <FormItem label="甲方名称" prop="name">
              <i-input size="small" v-model="firstParty.name" placeholder="请输入甲方名称"></i-input>
          </FormItem>
          <FormItem  prop="code">
              <span slot="label">纳税人识别号/社会统一信息代码</span>
              <i-input size="small" v-model="firstParty.code" placeholder="格式：91510100MA62L2A037、MA62L2A03、510108600454493" @on-blur="validateCustomer"></i-input>
          </FormItem>

          <Row :gutter="10">
              <i-col span="12">
                <FormItem label="联系人" prop="contact">
                    <i-input size="small" v-model="firstParty.contact" placeholder="请输入 联系人"></i-input>
                </FormItem>
              </i-col>
              <i-col span="12">
                <FormItem label="联系电话" prop="tel">
                    <i-input size="small" v-model="firstParty.tel" placeholder="请输入 联系方式"></i-input>
                </FormItem>
              </i-col>
               <i-col span="12">
              <FormItem label="电子邮箱" prop="email">
                  <i-input size="small" v-model="firstParty.email" placeholder="请输入电子邮箱"></i-input>
              </FormItem>
            </i-col>
            <i-col span="12">
              <FormItem label="传真" prop="fax">
                  <i-input size="small" v-model="firstParty.fax" placeholder="请输入传真"></i-input>
              </FormItem>
            </i-col>
          </Row>

          <FormItem label="甲方地址" prop="address">
              <i-input size="small" v-model="firstParty.address" placeholder="请输入 地址"></i-input>
          </FormItem>

          <FormItem label="发票类型" prop="invoiceType">
            <RadioGroup size="small" v-model="firstParty.invoiceType" type="button">
              <Radio v-for="invoice in invoiceTypes" :key="invoice.value" :label="invoice.value">{{invoice.name}}</Radio>
            </RadioGroup>
          </FormItem>

          <Row :gutter="10">
              <i-col span="12">
                <FormItem label="开户行" prop="bank" :required="required" :show-message="false">
                    <i-input size="small" v-model="firstParty.bank" placeholder="请输入 开户行"></i-input>
                </FormItem>
              </i-col>
              <i-col span="12">
                <FormItem label="银行账号" prop="bankAccount" :required="required" :show-message="false">
                    <i-input size="small" v-model="firstParty.bankAccount" placeholder="请输入 银行账号"></i-input>
                </FormItem>
              </i-col>
          </Row>
      </i-form>

      <div slot="footer">
            <Button type="text"  @click="handleReset">重置</Button>
            <Button type="primary" :loading="loading" @click="handleSubmit">保存</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import { addCustomer, getAdvertiserCustomerPage, checkCustomerRepeat } from '@/api/scp/myfirstparty'
import { getAdvertiser } from '@/api/crm/advertiser'
import { formatInvoiceType } from '@/utils/tagStatus'

export default {
  props: {
    current: Object,
    advertiserId: {
      type: [Number, String],
      required: true
    },
    userId: {
      type: [Number, String],
      required: true
    }
  },
  data () {
    return {
      loading: false,
      modalNewFirstParty: false,
      customers: [],
      titles: [
        { title: '甲方名称', key: 'name' },
        { title: '联系人', key: 'contact' },
        { title: '联系电话', key: 'tel' },
        {
          title: '发票类型',
          width: 90,
          render: (h, data) => {
            return formatInvoiceType(h, data.row.invoiceType, data.row.invoiceTypeName)
          }
        },
        {
          title: '操作',
          width: 90,
          render: (h, data) => {
            if (data.row.id === this.current.id) {
              return h('span', {
                style: {
                  color: '#fff'
                }
              }, '已选')
            } else {
              return h('a', {
                on: {
                  click: () => {
                    this.chooseCustomer(data.row)
                  }
                }

              }, '选择')
            }
          }
        }

      ],
      query: {
        advertiserId: this.advertiserId,
        salesmanId: this.userId,
        pageNumber: 1,
        pageSize: 10,
        keyword: ''
      },
      total: 0,
      firstParty: {
        name: '',
        address: '',
        tel: '',
        email: '',
        fax: '',
        contact: '',
        invoiceType: 0,
        bank: '',
        bankAccount: '',
        code: ''
      },
      rules: {
        name: [{ required: true, message: ' ' }],
        tel: [{ required: true, message: ' ', trigger: 'blur' }, // （支持手机号码，3-4位区号，7-8位直播号码，1－4位分机号）
          { pattern: /((\d{11})|^((\d{7,8})|(\d{4}|\d{3})-(\d{7,8})|(\d{4}|\d{3})-(\d{7,8})-(\d{4}|\d{3}|\d{2}|\d{1})|(\d{7,8})-(\d{4}|\d{3}|\d{2}|\d{1}))$)/, message: ' ' }
        ],
        contact: [{ required: true, message: ' ' }],
        code: [
          { required: true, message: ' ', trigger: 'blur' },
          { pattern: /^([a-zA-Z0-9]{2}[0-9]{6}[a-zA-Z0-9]{10}|[a-zA-Z0-9]{9}|[a-zA-Z0-9]{15})$/, message: ' ' }
        ]
      },
      invoiceTypes: [
        { value: 0, name: '专票' },
        { value: 1, name: '普票' }
      ],
      required: true,
      repeat: false,
      oldBank: '',
      oldBankAccount: ''
    }
  },
  methods: {
    getList () {
      getAdvertiserCustomerPage(this.query).then(data => {
        this.customers = data.list
        this.total = data.totalRow
      })
    },
    chooseCustomer (customer) {
      this.$emit('on-chosen', customer)
    },
    handleCreate () {
      this.modalNewFirstParty = true
    },
    handlePageChange (page) {
      this.query.pageNumber = page
      this.getList()
    },
    handleSearch () {
      this.query.pageNumber = 1
      this.getList()
    },
    validateCustomer () {
      checkCustomerRepeat({ code: this.firstParty.code }).then(res => {
        this.repeat = res.length > 0
      })
    },
    handleReset () {
      this.$refs.firstPartyForm.resetFields()
    },
    handleSubmit () {
      this.$refs.firstPartyForm.validate(valid => {
        if (valid) {
          this.loading = true
          this.firstParty.advertiserId = this.advertiserId
          addCustomer(this.firstParty).then(res => {
            if (res && !res.errcode) {
              this.$Notice.success({ desc: '添加合同甲方成功！' })
              // // 更新列表
              this.modalNewFirstParty = false
              this.getList()
            }
            this.loading = false
          })
        }
      })
    },
    loadDefaultFirstPartyInfo () {
      getAdvertiser({ advertiserId: this.advertiserId }).then(res => {
        this.firstParty = {
          name: res.name,
          address: res.address,
          tel: res.tel,
          contact: '',
          invoiceType: 0,
          bank: '',
          bankAccount: '',
          code: res.orgCode
        }
      })
    }
  },
  created () {
    this.getList()
    this.loadDefaultFirstPartyInfo()
  },
  watch: {
    'firstParty.bank' (newVal, oldVal) {
      this.oldBank = oldVal
    },
    'firstParty.bankAccount' (newVal, oldVal) {
      this.oldBankAccount = oldVal
    },
    'firstParty.invoiceType': function () {
      this.required = this.firstParty.invoiceType === 0
      if (this.required) {
        this.firstParty.bank = this.oldBank
        this.firstParty.bankAccount = this.oldBankAccount
      }
    }
  }
}
</script>
